import React, { ReactElement } from 'react'

import Layout from '@/components/layout'

function Page(): ReactElement {
  return (
    <Layout>
      <h1>Klassisk rehabilitering</h1>
      <p>
        Kiropraktoren vil ved behov bruke ulike øvelser i behandlingen for å styrke viktige muskler og skape
        bevegelighet i ledd.
      </p>
    </Layout>
  )
}

export default Page
